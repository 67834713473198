import React from "react";

import Container from "../container/Container";

export default function PageFooter() {
  // return (
  //   <div className="bg-footer bg-no-repeat bg-fill md:bg-cover bg-center opacity-70 h-[460px] bg-fixed">
  //     PageFooter
  //   </div>
  // );

  return (
    <section id="directors" className="h-full">
      <Container>
        <div className="px-[15px] md:px-0f pt-14">
          <div className="w-full md:w-1/2">
            <h2 className="text-[40px] text-bgd-footer_title_color text-center md:text-left mb-10">
              BAYANGOL DISTRICTS GOVERNORS OFFICE
            </h2>

            <p className="text-center md:text-left mb-[25px] text-bgd-footer_text_color">
              Mongolia, Ulaanbaatar city, Bayangol district, 4th khoroo, Zamchid
              street – 3, Bayangol District Governor's Office
            </p>
            <p className="text-center md:text-left mb-[25px] text-bgd-footer_text_color">
              Phone : (976)-70120320
            </p>
            <p className="text-center md:text-left mb-[25px] text-bgd-footer_text_color">
              Email : info@bgd.mn <br /> Inquiries : info@bgd.mn
            </p>
            <p className="text-center md:text-left mb-[25px] text-bgd-footer_text_color">
              Mon-Fri: 9am to 6pm
            </p>

            <div className="">
              <ul className="flex flex-row gap-2">
                <li className="flex items-center justify-center h-[30px] w-[30px] rounded-full hover:text-white hover:bg-bgd-social_fb_color hover:cursor-pointer">
                  <i className="fa-brands fa-facebook-f" />
                </li>
                <li className="flex items-center justify-center h-[30px] w-[30px] rounded-full hover:text-white hover:bg-bgd-social_twitter_color hover:cursor-pointer">
                  <i className="fa-brands fa-twitter" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
