import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "./components/layout/Layout";

const HomePage = React.lazy(() => {
  return import("./pages/home/Home.js");
});
const AboutPage = React.lazy(() => {
  return import("./pages/about/AboutPage.js");
});
const NewsDetailPage = React.lazy(() => {
  return import("./pages/home/NewsDetail.js");
});

export default function AllRoutes() {
  const routes = [
    {
      path: "/",
      component: <HomePage />,
      exact: true,
    },
    {
      path: "/about-us",
      component: <AboutPage />,
      exact: true,
    },
    {
      path: "/news/:id",
      component: <NewsDetailPage />,
      exact: true,
    },
  ];

  return (
    <Layout>
      <Suspense
        fallback={
          <div className="flex justify-center items-center mt-[250px]">
            {"Loading..."}
          </div>
        }
      >
        <Routes>
          {routes.map(({ path, component, exact = true }) => (
            <Route key={path} path={path} exact={exact} element={component} />
          ))}
        </Routes>
      </Suspense>
    </Layout>
  );
}
