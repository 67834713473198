import React, { useState } from "react";

import { Modal } from "antd";

export default function Menu() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const menuData = [
    {
      label: "Home",
      key: "/",
      goto: "home",
    },
    {
      label: "News",
      key: "/news",
      goto: "news",
    },
    {
      label: "Greetings",
      key: "/greetings",
      goto: "",
    },
    {
      label: "Directors",
      key: "/directors",
      goto: "directors",
    },
    {
      label: "Contact",
      key: "/contact",
      goto: "contact",
    },
  ];

  const onHandleClickScroll = (pElement) => {
    if (pElement !== "") {
      const element = document.getElementById(pElement);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      onShowModal();
    }
  };

  const onShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="hidden md:block">
      <div className="flex flex-row gap-[30px]">
        {menuData.map((row, index) => (
          <div
            key={index}
            className="text-lg uppercase hover:cursor-pointer group text-bgd-footer_text_color transition-all duration-300 ease-in-out"
            onClick={() => onHandleClickScroll(row.goto)}
          >
            <span className="bg-left-bottom bg-gradient-to-r from-bgd-footer_title_color to-bgd-footer_text_color bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
              {row.label}
            </span>
          </div>
        ))}
      </div>

      <Modal
        title="Greetings"
        centered
        open={isModalOpen}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        footer={false}
        width={800}
        style={{ fontFamily: "Oswald, sans-serif" }}
        bodyStyle={{ fontSize: "16px" }}
      >
        <div className="text-bgd-footer_title_color">
          <img
            src="http://admin.bgd.mn/couch/uploads/image/2022/0-02-08-97a6293f29f4cfa916818c3c26fccba7806b08866737cbb04b22ecbf429ac8fe_3a332f99cdf5b5d1.jpg"
            alt="img"
          />
          <br />
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;Greetings to you and all the citizens of
            Bayangol district visiting BGD.MN, the official news channel of
            Bayangol district.
          </p>
          <br />
          <p>
            Bayangol district, which is one of the 6 districts of Ulaanbaatar
            city center, borders Sukhbaatar and Chingel in the east, Khan-Uul in
            the south, and Songinohairkhan district in the west and north. It is
            a youth district with an area of 2949 hectares, 62.8 thousand
            households and 236.4 thousand people, and 60.9 percent of all
            citizens are under the age of 35.
          </p>
          <br />
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;Bayangol district leads the capital in terms
            of the quality and accessibility of schools, kindergartens, and
            health care facilities that are a mine of education, and the largest
            energy plant in our district, &quot;Thermal Power Plant-4&quot;
            State-Owned Joint Stock Company, &quot;Thermal Power Plant No.
            2&quot; State-Owned Joint-Stock Company, the Mongolian-Russian joint
            &quot;Ulaanbaatar Railway&quot;, the state-owned hospitals of
            National Center for Maternal and Child Health, the Third State
            Central Hospital, and the National Center for Traumatology are
            located in Bayangol district. It is also distinguished by the
            location of media organizations such as Mongolian National
            broadcasting Television and Radio and UBS, art and culture centers
            such as Central Palace of Trade Union Culture of Mongolia, Song and
            Dance Ensemble of Railway, and one of the oldest historical
            monuments, the Buddhist center of Mongolia, Gandantekchenlin
            Monastery. In addition, there are 3rd and 4th districts, which are
            large residential areas located in our district, and shopping
            centers such as &quot;Kharkhorin&quot;, &quot;Bars&quot;-1 and 2
            operate.
          </p>
          <br />
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;It has been more than two years since I was
            appointed as the governor of Bayangol district, and during this
            time, my team has always listened to the citizens and accepted their
            suggestions in a healthy and safe environment in order to fulfill
            the trust and message entrusted to us by the citizens, residents and
            voters of the district. In order to provide comfortable living
            conditions, the necessary investment and development works are being
            implemented step by step.
          </p>
          <br />
          <p>
            Also, our team is developing a policy document that determine the
            future development of our district, and we believe that this
            document is the key to the future development of Bayangol district.
          </p>
          <br />
          <p>
            Dear all of you, by visiting the BGD.MN site, you will be able to
            get acquainted with the 2020-2024 activity program of the Bayangol
            District Governor, as well as receive information about the projects
            and programs implemented in the social sector such as investment,
            development, education and health in the district.
          </p>
          <br />
          <p>
            Also, if YOU want to contact us and send us your feedback, please
            visit my official website
          </p>
          <br />
          <p>Sumyabaatar of Ulzii-Orshikh</p>
          <br />
          <p>Bayangol District Emergency Headquarters Facebook page</p>
          <br />
          <p>Call 1800-1222 and submit your feedback.</p>
          <br />
          <p>
            Sincerely yours, Representative of City Council, Governor of
            Bayangol District O. SUMYAABAATAR
          </p>
          <p>&nbsp;</p>
        </div>
      </Modal>
    </div>
  );
}
