import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Container from "../container/Container";
import Menu from "../menu/Menu";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrollActive, setScrollActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollActive(window.scrollY > 20);
    });
  }, []);

  const onHandleMenu = () => {
    setShowMenu(!showMenu);

    if (!showMenu) {
      onShowDrawer();
    }
  };

  const onShowDrawer = () => {
    setDrawerOpen(true);
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setShowMenu(false);
  };

  // const onClickMenu = (event) => {
  //   navigate(event.key);
  //   onCloseDrawer();
  // };

  return (
    <header
      className={`sticky top-0 z-50 bg-white ${
        scrollActive ? `shadow-md pt-0` : `pt-4`
      }`}
    >
      <Container>
        <div className="flex items-center justify-between h-[65px] lg:h-[64px]">
          {/* Logo */}
          <>
            <Link to="/">
              <img
                className="lg:h-[55px] h-[40px] cursor-pointer"
                src="/assets/images/header_logo300px.png"
                alt="logo"
              />
            </Link>
          </>

          {/* Menu */}
          <>
            <Menu />
          </>

          {/* <>
            <button className="">Contact Us</button>
          </> */}

          {/* Hamburger menu icon */}
          <>
            <div className="flex md:hidden cursor-pointer px-6 py-[22px]">
              <i
                className="fa-solid fa-bars text-lg text-bgd-main_blue_color"
                onClick={onHandleMenu}
              />
            </div>
          </>
        </div>
      </Container>
    </header>
  );
}
