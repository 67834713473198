import React from "react";

import Header from "../header/Header";
import PageFooter from "../footer/PageFooter";
import ScrollToTop from "../../settings/ScrollToTop";

export default function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow pt-0 px-[0px] lg:px-[0px] lg:space-y-3">
        <main>{children}</main>
      </div>
      <PageFooter />
      <ScrollToTop />
    </div>
  );
}
